body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
  background-color: #323232;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: helvetica;
}

.Form {
  color: white;
}

.Header{
  background-color: #529857;
}

.Logo{
    width: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Logo-user{
    width: 50%;
    padding-bottom: 5px;
    margin-top: 5px;
    float:left;
}


.custom-tooltip{
  color: white;
  font-size: 17px;
}

.Local-button {
  width: 75%;
  font-size: 35px;
  background-color: #323232;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  font-weight: bold;
  outline: none;
  border: none;
}

.Title {
  font-size: 35px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.Settings-wrapper {
  background: #3c3c3c;
}
.Device-wrapper {
  background: #3c3c3c;
}
.NewNotes-wrapper {
  background: #3c3c3c;
}
.Homebuttonwrapper-100 {
  background: #3c3c3c;
}
.Homebutton-100{
    color: white;
    font-size: 22pt;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 95%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.Homebuttonwrapper-90 {
  background: #666666;
  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
}
.Overviewbutton-home {
  background: #666666;
  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
}
.Homebutton-90{
    color: white;
    font-size: 14pt;
    font-weight: bold;
    background-color: #666666;
    border: none;
    display: inline-block;
    width: 85%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.Homebuttonwrapper-80 {
  background: #8c8b8b;
  width: 80%;
  margin-left: 10%;
  border-radius: 10px;
}
.Homebutton-80{
    color: white;
    font-size: 14pt;
    font-weight: bold;
    background-color: #8c8b8b;
    border: none;
    display: inline-block;
    width: 75%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.Overview-button{
  color: white;
  font-size: 22pt;
  font-weight: bold;
  background-color: #666666;
  border: none;
  display: inline-block;
  width: 85%;
  margin-bottom: 19px;
  margin-top: 19px;
}
.Settings-header {
  font-size: 30px;
  text-align: center;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.Title {
  font-size: 35px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.Note-button {
  width: 98%;
  font-size: 20px;
  background-color: #3c3c3c;
  display: block;
  text-align: left;
  margin-bottom: 1.25%;
  color: white;
  padding: 10px;
  margin-top: 15px;
  outline: none;
  border: none;
}
.Docs-button {
  width: 98%;
  font-size: 20px;
  background-color: #3c3c3c;
  display: block;
  text-align: left;
  margin-bottom: 1.25%;
  color: white;
  padding: 10px;
  margin-top: 15px;
  outline: none;
  border: none;
}
.Created-at {
  font-size: 15px;
  display: block;
  text-align: right;
  color: white;
}
.Posted-by {
  font-size: 15px;
  display: block;
}

.div-height {
  height: 200px;
}

.Status-button {
  width: 46%;
  height: 80px;
  font-size: 30px;
  background-color: #3c3c3c;
  display: inline-block;
  text-align: center;
  margin-left: 2.5%;
  margin-top: 1.25%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  float: left;
  outline: none;
  border: none;
}

.Status-reading{
  color: white;
  font-weight: bold;
}
.Status-container {
  text-align: center;
  display: inline-block;
  width: 100%;
}
.Status-title {
  font-size: 15px;
}
.Timestamp-button {
  width: 75%;
  font-size: 20px;
  background-color: #3c3c3c;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  border-radius: 7px;
  margin-top: 0px;
  margin-bottom: 0px;
  outline: none;
  border: none;
}

.Status-wrapper{
  background-color: #3c3c3c;
}
.graphs-container{
  width: 100%;
  float: left;
}
.Space-div{
  height: 50px;
  clear: both;
}
.Space-div2{
  clear: both;
}
.Half-space{
  height: 10px;
}
.One-space{
  height: 20px;
}
.Two-space{
  height: 30px;
}
.Eight-space{
  height: 160px
}
.Text-area {
  width: 92%;
  font-size: 20px;
  background-color: #c7c1c1;
  text-align: left;
  margin-top: 10px;
  height: 80px;
  color: #323232;
  padding-left: 10px;
  outline: none;
  border: none;
  border-radius: 7px;
}
.Email-area {
  width: 80%;
  font-size: 15px;
  background-color: #c7c1c1;
  text-align: left;
  margin-top: 10px;
  height: 40px;
  color: #323232;
  outline: none;
  border: none;
  border-radius: 7px;
}
.Text-line {
  width: 92%;
  font-size: 25px;
  background-color: #c7c1c1;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #323232;
  padding-left: 10px;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 7px;
}
.Emoji-icon{
  height: 45px;
  float: left;

}
.Select-button {
  width: 30%;
  font-size: 10px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-left: .5%;
  margin-top: 1.25%;
  color: white;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Icon-label {
  width: 60%;
  font-size: 25px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color: white;
  padding: 2px;
  position: relative;
}
.Icon-orderlabel {
  width: 80%;
  font-size: 20px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
}
.Icon-submit {
  width: 45%;
  font-size: 30px;
  background-color: #529857;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 15px;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  -webkit-appearance: none;
  outline: none;
  border: none;
}

.version-number {
  font-size: 20px;
  text-align: center;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.submit-buttons {
  width: 45%;
  font-size: 30px;
  background-color: #529857;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 15px;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  -webkit-appearance: none;
  outline: none;
  border: none;
}

.Icon-box {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  margin-right: 1.25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Icon-box2 {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  margin-left: 1.25%;
  margin-right: 1.25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Icon-box3 {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  margin-left: 1.25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Icon-box4 {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  margin-left: 2.5%;
  margin-right: 1.25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Icon-box5 {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  margin-left: 1.25%;
  margin-right: 2.5%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}


.Icon-box-form {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: .25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.Icon-box-form2 {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.Analog-box {
  width: 20%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-left: 1.25%;
  margin-right: 1.25%;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.Reading-name {
  width: 70%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: inline-block;
  text-align: center;
  margin-top: 0%;
  margin-bottom: 0%;
  color: #323232;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
  height: 30px;
  font-weight: bold;
}
.Time-wrapper{
  width: 70%;
  display: inline-block;
  text-align: center;
  background-color: #3c3c3c;
  border-radius: 7px;
  margin-top: 25px;

}
.Day-wrapper{
  width: 90%;
  display: inline-block;
  text-align: center;
  background-color: #3c3c3c;
  border-radius: 7px;
  margin-top: 15px;
}
.Day-graphs {
  width: 25%;
  font-size: 20px;
  background-color: #323232;
  color: white;
  text-align: center;
  border-radius: 7px;
  padding: 0px;
  outline: none;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Months-wrapper{
  width: 90%;
  display: inline-block;
  text-align: center;
  background-color: #3c3c3c;
  border-radius: 7px;
  margin-top: 15px;
}
.All-wrap {
  width: 25%;
  margin-left: 37.5%;
}
.Months-buttons {
  width:25%;
  display: inline-block;
}
.Months-button100 {
  width: 100%;
  font-size: 20px;
  background-color: #323232;
  color: white;
  text-align: center;
  border-radius: 7px;
  padding: 0px;
  outline: none;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Button2-wrapper{
  width: 50%;
  display: inline-block;
  text-align: center;
  background-color: #3c3c3c;
  border-radius: 7px;
  margin-top: 15px;
}

.Button2-wrapper-alarm{
  width: 50%;
  display: inline-block;
  text-align: center;
  background-color: #666666;
  border-radius: 7px;
  margin-top: 15px;
}


.Graph-time {
  width: 33.3%;
  font-size: 20px;
  font-weight: bold;
  background-color: #3c3c3c;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Button2-select {
  width: 50%;
  font-size: 20px;
  font-weight: bold;
  background-color: #3c3c3c;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Button2-select-alarm {
  width: 50%;
  font-size: 20px;
  font-weight: bold;
  background-color: #666666;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.Overviewbutton-wrapper{
  width: 80%;
  display: inline-block;
  text-align: center;
  background-color: #3c3c3c;
  border-radius: 7px;
  margin-top: 15px;

}
.Time-box {
  width: 25%;
  font-size: 20px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-right: 1.25%;
  margin-left: 1.25%;
  margin-top: 5%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Time-box2 {
  width: 25%;
  font-size: 20px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-left: 1.25%;
  margin-top: 5%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.Time-box3 {
  width: 25%;
  font-size: 20px;
  background-color: #323232;
  display: inline-block;
  text-align: center;
  margin-left: 1.25%;
  margin-right: 1.25%;
  margin-top: 5%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.Locationview-buttonswrap {
  background-color: #3c3c3c;
  width: 90%;
  margin-left: 5%;
  padding-top: 5px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.Location-button {
  width: 20%;
  font-size: 20px;
  background-color: #3c3c3c;
  display: inline-block;
  text-align: center;
  margin-top: 0%;
  color: white;
  padding: 2px;
  position: relative;
  outline: none;
  border: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.Download-button {
  width: 15%;
  background-color: #666666;
  margin-left: 80%;
  margin-bottom: 5px;
  text-align: center;
  padding: 2px;
  outline: none;
  border: none;
  border-radius: 7px;
}

.Add-buttonwrapper {
  width: 80%;
  background-color: #666666;
  margin-top: 10px;
  margin-left: 10%;
}

.Add-button {
  color: white;
  font-size: 18pt;
  outline: none;
  border: none;
  font-weight: bold;
  background-color: #666666;
  display: inline-block;
  width: 60%;
  height: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 7px;
  text-align: center;
}
.Iconemo-container{
  text-align: center;
  width: 100%;
}
.Icon-emoji {
  height: 30px;
  margin-left: 4.5%;
  margin-right: 4%;
  display: inline-block;
}
.Icon-emoji2 {
  height: 30px;
  margin-left: 4.5%;
  margin-right: 4%;
  display: inline-block;
}
.Emoji{
  float: left;
  height: 30px;
}
.Emoji1 {
  text-align: center;
  height: 20px;
  margin-top: 2.5%;
}
.Emoji2{
  float: left;
  height: 20px;
}
.Submit-button {
  width: 75%;
  font-size: 30px;
  background-color: #529857;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 2.5%;
  margin-bottom: 5%;
  -webkit-appearance: none;
  outline: none;
  border: none;
}



.Show-all {
  width: 75%;
  font-size: 30px;
  background-color: #529857;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 10%;
  margin-bottom: 5%;
  -webkit-appearance: none;
  outline: none;
  border: none;
}
.Loading-button {
  width: 75%;
  font-size: 30px;
  background-color: #323232;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 2.5%;
  margin-bottom: 5%;
  -webkit-appearance: none;
  outline: none;
  border: none;
}
.Loading-buttonstatus {
  width: 75%;
  font-size: 30px;
  background-color: #3c3c3c;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 2.5%;
  margin-bottom: 5%;
  -webkit-appearance: none;
  outline: none;
  border: none;
}
.Loading-noback {
  width: 75%;
  font-size: 30px;
  display: block;
  text-align: center;
  margin-left: 12.5%;
  color: white;
  padding: 2px;

}

.Loading-inbanner {
  width: 90%;
  font-size: 30px;
  background-color: #3c3c3c;
  display: block;
  text-align: center;
  margin-left: 5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 2.5%;
  margin-bottom: 5%;
  -webkit-appearance: none;
  outline: none;
  border: none;
}
.Home-button {
  width: 20%;
  font-size: 20px;
  background-color: #529857;
  display: block;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 3%;
  margin-bottom: 5%;
  float: left;
  outline: none;
  border: none;
}

.Back-wrap {
  width: 20%;
  font-size: 20px;
  background-color: #529857;
  display: block;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 3%;
  margin-bottom: 5%;
  float: left;
  outline: none;
  border: none;
}

.Settings-button{
  width: 20%;
  font-size: 20px;
  background-color: #529857;
  display: block;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 3%;
  margin-bottom: 5%;
  float: right;
  outline: none;
  border: none;

}

.Nav-header{
  background-color: #529857;
  height: 50px;
}

.Back-button{
  height: 25px;
}
.Plus-button{
  height: 30px;
  float: left;
}
.Settings-icon{
  height: 25px;
}
.Locationback-button {
  width: 45%;
  font-size: 30px;
  background-color: #529857;
  display: block;
  text-align: center;
  margin-left: 27.5%;
  color: white;
  padding: 2px;
  border-radius: 7px;
  margin-top: 5%;
  margin-bottom: 5%;
}
.graphs{
  background-color: #323232;
  color: #000000;
  height: 200px;
  width: 95%;
  margin: 2.5%;
  border-radius: 7px;
}
.graphs-dash{
  background-color: #323232;
  color: #000000;
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
}
.graphs-container{
  width: 100%;
  height: 100%;
}
.File-upload{
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 95%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: block;
  text-align: center;
  margin-left: 2.5%;
  color: #323232;
  border-radius: 7px;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 2px;
  outline: none;
  border: none;
}

input[type="file"]::-webkit-file-upload-button{
  -webkit-appearance: none;
  font-size: 20px;
  background-color: #666666;
  color: white;
  margin-left: 0%;
  margin-right: 20%;
  border-radius: 7px;
  outline: none;
  border: none;
  height: 35px;
}

.File-select{
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 95%;
  font-size: 20px;
  background-color: #c7c1c1;
  display: block;
  margin-left: 2.5%;
  color: #323232;
  border-radius: 7px;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 2px;
  outline: none;
  border: none;
}

.input-wrapper{
  border: 2px solid white;
  width: 70%;
  margin: 0 auto;
}

.form-name{
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: .5em;
  color: white;
}

.form-text{
  font-size: 14pt;
}

.form-text-labels{
  width: 20%;
  font-size: 12pt;
  display: inline-block;
  text-align: center;
  margin-left: 1.25%;
  margin-right: 1.25%;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}
.form-text-labels2{
  width: 20%;
  font-size: 15pt;
  display: inline-block;
  text-align: center;
  margin-left: 4.5%;
  margin-right: 4.5%;
  margin-top: 1.25%;
  margin-bottom: 0%;
  color: white;
  padding: 2px;
  position: relative;
  border-radius: 7px;
  outline: none;
  border: none;
}

.rem-pass-title{
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 2pt;
  color: white;
}

.rem-pass-toggle{
  width: 20%;
  margin-bottom: 1.5%;
  font-size: 9pt;
  text-align: center;
  color: white;
  padding: 2px;
  border-radius: 7px;
  outline: none;
  border: none;
}


.signin-link{
    color: #529857;
    font-size: 12pt;
    font-weight: bold;
    margin-top: 1%;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 51%;
}

.signup-link{
    color: #529857;
    font-size: 16pt;
    font-weight: bold;
    background-color: #323232;
    border: none;
    display: inline-block;
    width: 75%;
    margin-bottom: 10px;
}

.notesdoc-link{
    color: white;
    font-size: 26pt;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 70%;
    margin-bottom: 20px;
    margin-top: 20px;
}
.notesdocopen-link{
    color: white;
    font-size: 36pt;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 95%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.notesdoc-icon{
  height: 45px;
  float: left;
  margin-left: 5%;
  margin-top: 15px;
}

.org-get-id{
    color: white;
    font-size: 18pt;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 70%;
    margin-bottom: 25px;
    margin-top: 25px;
}

.add-link{
    color: #529857;
    font-size: 24pt;
    font-weight: bold;
    background-color: #323232;
    border: none;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
}

.resend-link{
  color: #529857;
  font-size: 16pt;
  font-weight: bold;
  background-color: #3c3c3c;
  border: none;
  display: inline-block;
  width: 95%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.confirm-link{
  color: #529857;
  font-size: 16pt;
  font-weight: bold;
  background-color: #3c3c3c;
  border: none;
  display: inline-block;
  width: 45%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.goto-device{
  color: #529857;
  font-size: 16pt;
  font-weight: bold;
  background-color: #3c3c3c;
  border: none;
  display: inline-block;
  width: 45%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.confirm-wrapper{
  display: inline-block;
  width: 100%;
}

.confirm-wrapper-full{
  display: inline-block;
  width: 80%;
}

.confirm-button{
  width: 25%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

.title-one {
  font-size: 35px;
  color: white;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.location-org {
  font-size: 35px;
  color: white;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: center;
}

.location-dev {
  font-size: 18pt;
  font-weight: bold;
  margin-bottom: 0px;
  color: white;
}

.title-two {
    font-size: 18pt;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
}

.copy-id {
  width: 100%;
  font-size: 16px;
  background-color: #3c3c3c;
  display: inline-block;
  text-align: center;
  color: white;
  height: 20px;
  outline: none;
  border: none;
}

.copy-link{
    color: #529857;
    font-size: 16pt;
    font-weight: bold;
    margin-top: 1%;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 51%;
}

.check-label {
  float: left;
}

.toggle-labels {
  font-size: 18pt;
  font-weight: bold;
  color: white;
  text-align: left;
  text-indent: 40px;
}

.checkbox-container {
  float: left;
  clear: both;
  margin-left: 50px;
}
.Logloc-wrapper{
  width: 90%;
  margin-left: 5%;
  margin-right: 0%;
}

.Log-background{
  color: #3c3c3c;
}

.Log-wrapper{
  width: 100%;
  margin-top: 10pt;
}
.Log-icon{
  height: 25px;
  margin-top: 5pt;
  margin-left: 2pt;
  float: left;
}
.Log-message{
  font-size: 12pt;
  margin-left: 10%;
  font-weight: bold;
  color: white;
  text-align: left;
}
.Log-timestamp{
  font-size: 12pt;
  margin-left: 10%;
  font-weight: bold;
  color: white;
  text-align: left;
}

.Two-button-wrapper{
  display: inline;
  margin: auto;
}

.Two-button{
  width: 28%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 11%;
  margin-right: 11%;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

.Subscribe-button{
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

.Dropdown-button-full{
  width: 28%;
  margin-left: 36%;
  margin-right: 36%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

.Subscribe-button-full{
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

.Dropdown-button{
  width: 28%;
  margin-left: 36%;
  margin-right: 36%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}
.Newnotes-drop-button{
  width: 28%;
  margin-left: 36%;
  margin-right: 36%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #666666;
  border: none;
  outline: none;
  border-radius: 7px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}
input[type="checkbox"] {
  width: 2em;
  height: 2em;
  border: 2px solid white;
  background-color: #666666;
  border-radius: 5pt;
}

input[type="checkbox"]::after {
  display: inline-block;
  content: ".";
  font-size: 1em;
  color: #529857;
  visibility: hidden;
  background-color: #529857;
  width: 1.7em;
  height: 1.7em;
  border-radius: 2pt;
}

input[type="checkbox"]:checked::after {
  visibility: visible;
}


/* Desktop */

@media only screen and  (min-device-width : 769px) {
  .App {
    text-align: center;
    background-color: #323232;
    font-family: helvetica;
    height: 100%;
  }

  .Header{
    background-color: #529857;
  }

  .Logo{
      width: 20%;
      padding-top: 5px;
      padding-bottom: 5px;

  }
  .Logo-user{
      width: 15%;
      margin-top: 1%;
      float:left;
  }
  .Nav-header{
    background-color: #529857;
    height: 90px;
  }

  .Dashboard-wrapper{
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    display: inline-block;
    -webkit-columns: 4;
       -moz-columns: 4;
            columns: 4;
  }

  .base-layout {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

  }
  .col-1 {
    display: grid;
    grid-gap: 2rem;
    grid-column: 1 / span 1;
    background-color: #323232;
    height: 100vh;
    overflow: hidden;
  }
  .col-2 {
    display: grid;
    grid-gap: 2rem;
    grid-column: 2 / span 1;
    align-self: flex-start;
    background-color: #323232;
    height: 100vh;
    overflow: hidden;
    background-image: url(/static/media/Nowledge-Logo_ALL-WHT.f928e7f6.png);
    background-repeat:no-repeat;
    background-position: center center;
    background-size: 35%;
  }
  .col-3 {
    display: grid;
    grid-gap: 2rem;
    grid-column: 3 / span 1;
    align-self: flex-start;
    background-color: #323232;
    height: 100vh;
    overflow: hidden;
  }

  .Home-button {
    width: 30%;
    height: 15px;
    font-size: 20px;
    background-color: #529857;
    display: inline-block;
    color: white;
    padding: 2px;
    border-radius: 7px;
    margin-top: 1.5%;
    margin-bottom: 5%;
    float: left;
    outline: none;
    border: none;
  }

  .Homebuttonwrapper-100 {
    background: #3c3c3c;
    width: 40%;
    display: inline-block;
    border-radius: 10px;
  }
  .Homebutton-100{
      color: white;
      font-size: 22pt;
      font-weight: bold;
      background-color: #3c3c3c;
      border: none;
      display: inline-block;
      width: 95%;
      margin-bottom: 25px;
      margin-top: 25px;
  }
  .Homebuttonwrapper-90 {
    background: #666666;
    width: 35%;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5%;
  }
  .Overviewbutton-home {
    background: #666666;
    width: 20%;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5%;
  }
  .Homebutton-90{
      color: white;
      font-size: 14pt;
      font-weight: bold;
      background-color: #666666;
      border: none;
      display: inline-block;
      width: 85%;
      margin-bottom: 25px;
      margin-top: 25px;
  }
  .Homebuttonwrapper-80 {
    background: #8c8b8b;
    width: 30%;
    margin-left: 0%;
    display: inline-block;
    border-radius: 10px;
  }
  .Homebutton-80{
      color: white;
      font-size: 14pt;
      font-weight: bold;
      background-color: #8c8b8b;
      border: none;
      display: inline-block;
      width: 85%;
      margin-bottom: 25px;
      margin-top: 25px;
  }
  .Overview-button{
    color: white;
    font-size: 28pt;
    font-weight: bold;
    background-color: #666666;
    border: none;
    display: inline-block;
    width: 85%;
    margin-bottom: 20px;
    margin-top: 20px;
}
  .Homebutton-20{
    height: 75px;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 24%;
    margin-left: .5%;
    margin-right: .5%;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .Homebutton-org{
    font-size: 18pt;
    margin-bottom: 5px;
  }
  .Home-button-nickname{
    font-size: 16pt;
  }

  .Back-wrap {
    background-color: #529857;
    color: white;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    float: left;
    outline: none;
    border: none;
  }

  .Back-button{
    height: 35px;
  }
  .Plus-button{
    height: 30px;
    float: left;
    padding-bottom: 5px;
  }

  .Settings-button{
    background-color: #529857;
    color: white;
    padding: 2px;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    float: right;
    outline: none;
    border: none;

  }

  .Settings-icon{
    height: 35px;
  }

  .Local-button {
    width: 75%;
    font-size: 60px;
    background-color: #323232;
    color: white;
    padding: 2px;
  }

  .Title {
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .title-one {
    font-size: 35pt;
    color: white;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: center;
  }
  .location-org {
    font-size: 35pt;
    color: white;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: center;
  }
  .location-dev {
    font-size: 30pt;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
  }

  .title-two {
      font-size: 30pt;
      font-weight: bold;
      margin-bottom: 5px;
      color: white;
  }

  .location-dev {
    font-size: 30pt;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
}
  .Settings-wrapper {
    background: #3c3c3c;
  }
  .Device-wrapper {
    background: #3c3c3c;
    width: 25%;
    margin-left: 37.5%;
    text-align: center;
    border-radius: 10px;
  }
  .pie-height{
    width: 100%;
    height: 200px;
  }

  .dash-notes-wrapper {
    background: #323232;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    border-radius: 10px;
    outline-style: solid;
    outline-color: #3c3c3c;
    outline-width: 2px;
  }
  .dash-logo{
    width: 15%;
    margin-top: 1%;
    padding-bottom: 5px;
    float:left;
  }

  .alarm-box-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
  }
  .alarm-box {
    background: #323232;
    align-self: center;
    height: 145px;
    width: 300px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    outline-style: solid;
    outline-color: #3c3c3c;
    outline-width: 2px;
  }

  .dash-online{
    font-size: 20px;
    width: 75px;
    padding-bottom: 5px;
    margin-top: 15px;
    float: right;
  }
  
  .font-15-30{
    font-size: 15px;
  }
  .font-35-70{
    font-size: 35px;
  }
  .font-20-40{
    font-size: 20px;
  }
  .font-30-60{
    font-size: 30px;
  }

  .NewNotes-wrapper {
    background: #3c3c3c;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    display: inline-block;
    text-align: center;
    border-radius: 10px;
  }
  .Settings-header {
    font-size: 40px;
    text-align: center;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }


  .Status-container {
    text-align: center;
    display: inline-block;
    width: 100%;

  }
  .Status-wrapper{
    background-color: #3c3c3c;
    width: 100%;
    display: inline-block;
  }

  .Status-button {
      width: 20%;
      height: 80px;
      font-size: 30px;
      background-color: #3c3c3c;
      text-align: center;
      margin-left: 2.5%;
      margin-right: 2.5%;
      margin-top: 1.25%;
      color: white;
      padding: 2px;

    }
  .Emoji-icon{
    height: 55px;
    float: left;

  }
  .Iconemo-container{
    width: 100%;
  }
  .Emoji{
    float: left;
    height: 30px;
  }
  .Emoji1 {
    text-align: center;
    height: 30px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .Emoji2{
    float: left;
    height: 20px;
  }
  .Timestamp-button {
    width: 75%;
    font-size: 20px;
    background-color: #3c3c3c;
    display: block;
    text-align: center;
    margin-left: 12.5%;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
    margin-bottom: 5px;
    outline: none;
    border: none;
  }

  .Submit-button {
    width: 20%;
    font-size: 30px;
    background-color: #529857;
    display: block;
    text-align: center;
    margin-left: 25%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2.5%;
    margin-bottom: 5%;
    -webkit-appearance: none;
  }
  .submit-buttons {
    width: 20%;
    font-size: 30px;
    background-color: #529857;
    display: block;
    text-align: center;
    margin-left: 40%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2.5%;
    margin-bottom: 5%;
    -webkit-appearance: none;
  }

  .Show-all {
    width: 20%;
    font-size: 30px;
    background-color: #529857;
    display: block;
    text-align: center;
    margin-left: 40%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 2%;
    -webkit-appearance: none;
  }
  .Loading-button {
    width: 30%;
    font-size: 30px;
    background-color: #323232;
    display: block;
    text-align: center;
    margin-left: 12.5%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2.5%;
    margin-bottom: 5%;
    margin-left: 35%;
    -webkit-appearance: none;
  }
  .Loading-buttonstatus {
    width: 30%;
    font-size: 30px;
    background-color: #3c3c3c;
    display: block;
    text-align: center;
    margin-left: 12.5%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2.5%;
    margin-bottom: 5%;
    margin-left: 35%;
    -webkit-appearance: none;
  }
  .Loading-noback {
    width: 33.3%;
    margin-left: 33.3%;
    margin-right: 33.3%;
    font-size: 30px;
    display: block;
    text-align: center;
    color: white;
    padding: 2px;
  
  }
  .Loading-inbanner {
    width: 70%;
    font-size: 30px;
    background-color: #3c3c3c;
    display: block;
    text-align: center;
    margin-left: 12.5%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2.5%;
    margin-bottom: 5%;
    -webkit-appearance: none;
  }
  .Note-button {
    width: 55%;
    font-size: 20px;
    background-color: #3c3c3c;
    display: inline-block;
    text-align: left;
    margin-left: 2%;
    margin-bottom: 1.25%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 5px;
  }
  .Docs-button {
    width: 20%;
    font-size: 20px;
    background-color: #3c3c3c;
    text-align: left;
    margin-left: 40%;
    margin-bottom: 1.25%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 5px;
    height: 75px
  }
  .Select-button {
    width: 20%;
    font-size: 10px;
    background-color: #323232;
    display: block;
    text-align: center;
    margin-left: 60%;
    margin-top: 1.25%;
    color: white;
    position: relative;
    border-radius: 7px;
    outline: none;
    border: none;
  }
  .Submit-form{
    width: 100%;
    display: inline-block;
  }
  .Locationback-button {
    width: 15%;
    font-size: 30px;
    background-color: #529857;
    display: block;
    text-align: center;
    margin-left: 10%;
    color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .resend-link{
    color: #529857;
    font-size: 30px;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 95%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .confirm-link{
    color: #529857;
    font-size: 30px;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 20%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .goto-device{
    color: #529857;
    font-size: 25px;
    font-weight: bold;
    background-color: #3c3c3c;
    border: none;
    display: inline-block;
    width: 80%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .confirm-wrapper{
    display: inline-block;
    width: 100%;
  }

  .confirm-wrapper-full{
    display: inline-block;
    width: 40%;
  }

  .confirm-button{
    width: 25%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  .Icon-label {
    font-size: 40px;
    background-color: #323232;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 1.25%;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-orderlabel {
    font-size: 30px;
    background-color: #323232;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box2 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box3 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box4 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-left: 8%;
    margin-right: 4%;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box5 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-left: 4%;
    margin-right: 8%;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box-form {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-top: .25%;
    margin-bottom: 2.25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Icon-box-form2 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-top: .25%;
    margin-bottom: 2.25%;
    margin-left: 5%;
    margin-right: 5%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Analog-box {
    width: 10%;
    height: 50px;
    font-size: 30px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: .25%;
    margin-bottom: .25%;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }

  .form-text-labels {
    width: 10%;
    height: 50px;
    font-size: 30px;
    display: inline-block;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: .25%;
    margin-bottom: .25%;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .form-text-labels2 {
    width: 10%;
    height: 50px;
    font-size: 30px;
    display: inline-block;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: .25%;
    margin-bottom: 0%;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Reading-name {
    width: 30%;
    height: 50px;
    font-size: 40px;
    background-color: #c7c1c1;
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #323232;
    padding: 2px;
    position: relative;
    border-radius: 7px;
    outline: none;
    border: none;
  }
  .signup-link{
      color: #529857;
      font-size: 30px;
      font-weight: bold;
      background-color: #323232;
      border: none;
      display: inline-block;
      width: 75%;
      margin-bottom: 10px;
  }
  .Time-wrapper{
    width: 20%;
    display: inline-block;
    text-align: center;
    background-color: #3c3c3c;
  }
  .Day-wrapper{
    width: 50%;
    display: inline-block;
    text-align: center;
    background-color: #3c3c3c;
    border-radius: 7px;
    margin-top: 15px; 
  }
  .Day-graphs {
    width: 12.5%;
    font-size: 20px;
    background-color: #323232;
    color: white;
    text-align: center;
    border-radius: 7px;
    outline: none;
    border: none;
  }
  
  .Months-wrapper{
    width: 30%;
    display: inline-block;
    text-align: center;
    background-color: #3c3c3c;
    border-radius: 7px;
    margin-top: 15px; 
  }
  .Months-button {
    width:25%;
    display: inline-block;
  }

  .All-wrap {
    width: 6.25%;
    margin-left: 46.875%;
  }
  .Months-button100 {
    width: 100%;
    font-size: 20px;
    background-color: #323232;
    color: white;
    text-align: center;
    border-radius: 7px;
    padding: 0px;
    outline: none;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .Graph-time {
    width: 33.3%;
    height: 40px;
    font-size: 25px;
    font-weight: bold;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
  }
  .Button2-wrapper{
    width: 15%;
    display: inline-block;
    text-align: center;
    background-color: #3c3c3c;
  }

  .Button2-wrapper-alarm{
    width: 75%;
    display: inline-block;
    text-align: center;
    background-color: #666666;
  }

  .Button2-select {
    width: 50%;
    height: 35px;
    font-size: 20px;
    font-weight: bold;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    position: relative;
    border-radius: 10px;
  }
  .Button2-select-alarm {
    width: 50%;
    height: 35px;
    font-size: 20px;
    font-weight: bold;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    position: relative;
    border-radius: 10px;
  }
  .Overviewbutton-wrapper{
    width: 30%;
    display: inline-block;
    text-align: center;
    background-color: #3c3c3c;
    border-radius: 7px;
    margin-top: 15px;
  }
  .Time-box {
    width: 10%;
    height: 40px;
    font-size: 30px;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
    margin-top: 1%;
  }
  .Time-box2 {
    width: 10%;
    height: 40px;
    font-size: 30px;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
    margin-top: 1%;
  }
  .Time-box3 {
    width: 10%;
    height: 40px;
    font-size: 30px;
    background-color: #666666;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
    margin-top: 1%;
  }

  .Locationview-buttonswrap {
    background-color: #3c3c3c;
    width: 40%;
    margin-left: 30%;
    padding-top: 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .Location-button {
    width: 20%;
    font-size: 20px;
    background-color: #3c3c3c;
    display: inline-block;
    text-align: center;
    margin-top: 0%;
    color: white;
    padding: 2px;
    position: relative;
    outline: none;
    border: none;
  }

  .Download-button {
    width: 5%;
    background-color: #666666;
    margin-left: 52%;
    margin-bottom: 5px;
    text-align: center;
    padding: 2px;
    outline: none;
    border: none;
    border-radius: 7px;
  }

  .Add-buttonwrapper {
    width: 80%;
    background-color: #323232;
  }

  .Add-button {
    color: white;
    font-size: 20pt;
    outline: none;
    border: none;
    font-weight: bold;
    background-color: #666666;
    display: inline-block;
    width: 15%;
    height: 60px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 7px;
    text-align: center;
  }
  

  .Icon-submit {
    width: 20%;
    font-size: 30px;
    background-color: #529857;
    display: inline-block;
    text-align: center;
    margin-top: 1.25%;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 10px;
    -webkit-appearance: none;
  }
  .Icon-emoji {
    font-size: 50px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    display: inline-block;
  }
  .Icon-emoji2 {
    font-size: 50px;
    margin-left: 4.25%;
    margin-right: 4.25%;
    display: inline-block;
  }
  .File-upload{
    width: 25%;
    margin-left: 37.5%;
    margin-right: 37.5%;
    font-size: 20px;
    background-color: #c7c1c1;
    display: block;
    text-align: left;
    color: #323232;
    padding: 2px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: .5%;
    -webkit-appearance: none;
  }
  .File-select{
    width: 25%;
    margin-left: 37.5%;
    margin-right: 37.5%;
    font-size: 20px;
    background-color: #c7c1c1;
    display: block;
    text-align: left;
    color: #323232;
    padding: 2px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 10px;
    -webkit-appearance: none;
  }

  .Text-area {
    width: 40%;
    font-size: 20px;
    background-color: #c7c1c1;
    height: 250px;
    color: #323232;
    margin-left: 30%;
    margin-right: 30%;
    outline: none;
    border: none;
  }

  .Email-area {
    width: 50%;
    font-size: 20px;
    background-color: #c7c1c1;
    text-align: left;
    margin-top: 10px;
    height: 50px;
    color: #323232;
    outline: none;
    border: none;
  }

  .Text-line {
    width: 30%;
    font-size: 30px;
    background-color: #c7c1c1;
    text-align: left;
    color: #323232;
    padding-right: 100px;
    outline: none;
    border: none;
    border-radius: 7px;
  }

  .input-wrapper{
    border: 2px solid white;
    width: 25%;
    margin: 0 auto;
  }

  .form-name{
    font-size: 22pt;
    font-weight: bold;
    color: white;
  }

  .form-text{
    font-size: 20pt;
  }

  .checkbox-container {
    float: left;
    clear: both;
    margin-left: 45%;
  }

  .Logloc-wrapper{
    width: 25%;
    margin-left: 38%;
    margin-right: 47%;
  }

  .Log-background{
    color: #3c3c3c;
  }

  .Log-wrapper{
    width: 100%;
  }
  .Log-icon{
    height: 35px;
    float: left;
  }
  .Log-message{
    font-size: 22pt;
    font-weight: bold;
    color: white;
  }
  .Log-timestamp{
    font-size: 12pt;
    font-weight: bold;
    color: white;
  }
  .Log-button{
    width: 33.3%;
    font-size: 20px;
    font-weight: bold;
    background-color: #3c3c3c;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 2px;
    position: relative;
    border-radius: 7px;
    outline: none;
    border: none;  
  }

  .Two-button-wrapper{
    display: inline;
    margin: auto;
  }
  
  .Two-button{
    width: 28%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 11%;
    margin-right: 11%;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  .Subscribe-button{
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  .Dropdown-button-full{
    width: 8.325%;
    margin-left: 45.83%;
    margin-right: 45.83%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  .Subscribe-button-full{
    width: 13%;
    margin-left: 43.5%;
    margin-right: 43.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }
  
  .Dropdown-button{
    width: 33.3%;
    margin-left: 33.3%;
    margin-right: 33.3%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  .Newnotes-drop-button{
    width: 16.65%;
    margin-left: 41.675%;
    margin-right: 41.675%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #666666;
    border: none;
    outline: none;
    border-radius: 7px;
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
 }

 @media only screen and (min-width: 3000px) {
  .App {
    text-align: center;
    background-color: #323232;
    font-family: helvetica;
    height: 100%;
  }
  .Half-space{
    height: 20px;
  }
  .One-space{
    height: 40px;
  }
  .Two-space{
    height: 60px;
  }

  .dash-logo{
    width: 15%;
    margin-top: 1%;
    padding-bottom: 5px;
    float:left;
  }
  .alarm-box-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 20px;
    gap: 20px 20px;
  }
  .alarm-box {
    background: #323232;
    align-self: center;
    height: 290px;
    width: 600px;
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    outline-style: solid;
    outline-color: #3c3c3c;
    outline-width: 4px;
  }
  .dash-online{
    font-size: 40px;
    width: 150px;
    padding-bottom: 10px;
    margin-top: 30px;
    float: right;
  }
  .graphs-dash{
    background-color: #323232;
    color: #000000;
    height: 300px;
    width: 100%;
    margin-bottom: 40px;
  }
  .pie-height{
    width: 100%;
    height: 400px;
  }
  .dash-notes-wrapper {
    background: #323232;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    border-radius: 20px;
    outline-style: solid;
    outline-color: #3c3c3c;
    outline-width: 4px;
  }
  .font-15-30{
    font-size: 30px;
  }
  .font-35-70{
    font-size: 70px;
  }
  .font-20-40{
    font-size: 40px;
  }
  .font-30-60{
    font-size: 60px;
  }
}

